import * as React from "react";
import RegisterView from "../views/RegisterView";
import Topbar from "../components/Topbar";
import FootView from "../views/FootView";
import useTranslation from "../hooks/useTranslation";

const RegisterPage = (props: any) => {
  return (
    <>
      <RegisterView pathName={props.location.pathname}></RegisterView>
    </>
  );
};

export default RegisterPage;
